<template>
    <div class="" style="padding-top: 1px">

        <div class="form">
            <van-field
                class="form-item disabled"
                readonly
                disabled
                :value="form.school"
                label="学校"
                placeholder="请选择学校"
                @click.native="$router.back()"
                :right-icon="type === 'edit' ? 'arrow' : ''"
            />
            <van-field class="form-item " readonly  :value="form.grade" @click="gradePicker = true" label="年级" placeholder="请选择所在年级" right-icon="arrow-down" />
            <van-field class="form-item " readonly :value="form.class" @click="classPicker = true" label="班级" placeholder="请选择所在班级"  right-icon="arrow-down" />
        </div>

        <div class="btn">
            <van-button type="primary" block @click="submit">确认</van-button>
        </div>



        <van-popup v-model="gradePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="gradeList"
                title="选择年级"
                @cancel="gradePicker = false"
                @confirm="onGradeConfirm"
            />
        </van-popup>

        <van-popup v-model="classPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="form.grade ? clastsList : []"
                title="选择班级"
                @cancel="classPicker = false"
                @confirm="onClassConfirm"
            />
        </van-popup>
 
    </div>
</template>

<script>
export default {
    name: "StudentHandle",
    data() {
        return {
            enterSchoolCode: false,
            schoolCodeState: null,
            schoolCode: '',  //学校编码


            gradePicker: false,
            gradeList: [{ text: '高中一年级', value: 1},{ text: '高中二年级', value: 1},{ text: '高中三年级', value: 1}],
            classPicker: false,
            clastsList: [{ text: '1001班', value: 1},{ text: '1002班', value: 1},{ text: '1003班', value: 1}],
           
            type: '', //新增或者修改
            studentId: '', // 为修改的时候 学生ID
            form: {
                grade: '',
                class: '',
                school: ''
            },

            form_backups: {

            }, //备份 用来恢复
        }
    },

    methods: {
        
        onGradeConfirm(item) {
            // item => { text: '高中一年级', value: 1}
            this.form.grade = item.text;
            this.gradePicker = false
        },
        onClassConfirm(item) {
            this.form.class = item.text;
            this.classPicker = false
        },

       
        submit() {
           
            if (!this.form.grade) {
                this.$toast('请选择所在年级');
                return;
            }
          
            if (!this.form.class) {
                this.$toast('请选择所在班级');
                return;
            }
            


            // TODO Send Server

                this.$toast.success('切换成功');
                setTimeout(() => {
                    this.$router.replace('/')
                }, 1500)
           
        },

    },

    mounted() {
        
        this.form.school = '骄阳演示学校'
        
    }
}

</script>
<style lang="less" scoped>

.form {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 29px 20px 24px 20px;
    margin: 0;
    .form-item {
        height: 40px;
        border: 1px solid #CCCCCC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 18px;
        margin-bottom: 20px;
        &.disabled {
            background: #F4F4F4;
        }

      
    }

    .tips {
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FF0000;
        line-height: 15px;
    }
}
.btn {
    padding: 20px 18px 30px 18px;
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    .van-button {
        flex: 1;
    }
   
}



::v-deep .van-field__label {
    width: 57px + 24px;
    font-size: 15px;
    color: #212121;
}
::v-deep .van-field__control {
    font-size: 15px;
}
::v-deep .van-field__control:disabled {
    color: #666;
    -webkit-text-fill-color: #666;
}
</style>
